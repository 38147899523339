import React from "react";
import { MdEmail, MdPhone } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-info">
          <p className="copyright">
            &copy; {new Date().getFullYear()} TinTech Solutions. All rights
            reserved.
          </p>
          <div className="contact-info">
            <a href="mailto:coopertintech@gmail.com" className="contact-item">
              <MdEmail size={18} />
              <span>coopertintech@gmail.com</span>
            </a>
            <a href="tel:+14802662083" className="contact-item">
              <MdPhone size={18} />
              <span>(480) 266-2083</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
