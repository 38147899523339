import React from "react";
import { FaEnvelope, FaCalendar } from "react-icons/fa";

const ContactPage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };

  return (
    <div className="contact-page" id="contact">
      <h2>Get in Touch</h2>
      <div className="contact-options">
        <div className="contact-email">
          <FaEnvelope className="contact-icon" />
          <h3>Send us an email</h3>
          <p>For all inquiries, please contact:</p>
          <a href="mailto:coopertintech@gmail.com">coopertintech@gmail.com</a>
        </div>
        <div className="contact-divider">
          <span>OR</span>
        </div>
        <div className="contact-schedule">
          <FaCalendar className="contact-icon" />
          <h3>Schedule a Consultation</h3>
          <p>Book a FREE 1-on-1 consultation</p>
          <button onClick={handleSchedule}>Schedule Now</button>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
